import React, {useEffect, useRef, useState} from 'react';
import Navbar from '../component/Navbar';
import {Container, Image, Sticky} from 'semantic-ui-react';
import {isMobile} from 'react-device-detect';

import Process from '../../images/process.png';

export default function HowTo() {
  const stickyRef = useRef();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const onScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div ref={stickyRef} style={{minHeight: '100vh', backgroundColor: '#00aed9'}}>
      <Sticky context={stickyRef}
              styleElement={{
                backgroundColor: '#00aed9',
                border: 0,
                borderStyle: 'dotted',
                borderBottomColor: '#f0f0f0',
                borderBottomWidth: window.scrollY >= 80 ? 1 : 0,
              }}>
        <Navbar />
      </Sticky>
      <Container style={{padding: isMobile ? '12px 2px' : 20}}>
        <Image src={Process}
               style={isMobile ? {display: 'block', margin: '0 auto'} : {
                 maxWidth: 800,
                 width: '100%',
                 display: 'block',
                 margin: '0 auto',
               }} />
      </Container>
    </div>
  );
}