import React, {useState, useEffect} from 'react';
import {TransitionablePortal, Button, Modal, Statistic, Divider, Message, Icon} from 'semantic-ui-react';
import {ReservationCheckExists} from '../../Consts.js';
import {useRecoilState} from 'recoil';
import {resultModalOpenState} from '../component/ReservationForm';
import moment from 'moment';

const CarmonReservationResult = ({modalOpen = false, data, reservationState}) => {
  const [open, setOpen] = useState(modalOpen);
  const [resultModalOpen, setResultModalOpen] = useRecoilState(resultModalOpenState);

  useEffect(() => {
    if (['production'].includes(process.env.RUN_MODE)) gtag('event', 'conversion', {'send_to': 'AW-10827242788/3bPJCPf43o4DEKS66qoo'});
    setOpen(modalOpen);
  }, [modalOpen]);

  return (
    <Modal
      centered={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny">
      <Modal.Header color="red">
        {reservationState === ReservationCheckExists.NEW ? '예약 접수가 완료되었습니다.' : '진행중인 예약정보가 있습니다.'}
      </Modal.Header>
      <Modal.Content>
        <Statistic size="mini" color="teal">
          <Statistic.Label style={{textAlign: `left`}}>예약번호</Statistic.Label>
          <Statistic.Value>{data && data.reservationNumber}</Statistic.Value>
        </Statistic>
        <Divider />

        <Statistic size="mini" color="teal">
          <Statistic.Label style={{textAlign: `left`}}>예약일시</Statistic.Label>
          <Statistic.Value>{data && moment(data.regTime).format(`YYYY-MM-DD HH:mm:ss`)}</Statistic.Value>
        </Statistic>
        <Divider />

        <Statistic size="mini" color="teal">
          <Statistic.Label style={{textAlign: `left`}}>차량번호</Statistic.Label>
          <Statistic.Value>{data && data.licensePlateNumber}</Statistic.Value>
        </Statistic>
        <Divider />

        <Statistic size="mini" color="teal">
          <Statistic.Label style={{textAlign: `left`}}>연락처</Statistic.Label>
          <Statistic.Value>{data && data.contactNumber}</Statistic.Value>
        </Statistic>
      </Modal.Content>
      <Modal.Actions>
        <Message icon
                 style={{textAlign: `left`}}
                 color="yellow">
          <Icon flipped="horizontally" name="talk" />
          <Message.Content>
            <Message.Header>카몬 매니저가 곧 연락드리겠습니다.</Message.Header>
          </Message.Content>
        </Message>

        <Button
          fluid
          size={'large'}
          style={{marginLeft: `0`}}
          content="확인"
          labelPosition="left"
          icon="checkmark"
          onClick={() => setResultModalOpen(false)}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CarmonReservationResult;