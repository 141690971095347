import React, {useEffect} from 'react';
import CarmonPusher from './Pusher';
import CarmonFooter from './Footer';
import background from '../background.jpg';

import LogRocket from 'logrocket';

const CarmonMainLayout = () => {
  useEffect(() => {
    if (process.env.RUN_MODE === 'production') {
      LogRocket.init('naagt0/carmon');
    }
  }, []);

  return (
    <div
      style={{
        // backgroundImage: `url(${background})`,
        backgroundPositionX: `50%`,
        backgroundSize: `cover`,
      }}>
      <div className="main-container"
           style={{
             /*background: `rgba(47, 103, 177, 0.3)`*/
             background: `#00aed9`,
           }}>
        <CarmonPusher />
      </div>
    </div>
  );
};

export default CarmonMainLayout;

