import {
  Card,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  Sticky,
} from "semantic-ui-react";
import React, { useEffect, useRef, useState } from "react";

import ABOUT from "../../images/about.001.png";
import Navbar from "../component/Navbar";
import { isMobile } from "react-device-detect";

export default function About() {
  const stickyRef = useRef();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const onScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div ref={stickyRef}>
      <div style={{ minHeight: "100vh", backgroundColor: "#00aed9" }}>
        <header>
          <Sticky
            context={stickyRef}
            styleElement={{
              backgroundColor: "#00aed9",
              border: 0,
              borderStyle: "dotted",
              borderBottomColor: "#f0f0f0",
              borderBottomWidth: scrollY >= 80 ? 1 : 0,
            }}
          >
            <Navbar />
          </Sticky>
        </header>

        <section
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Container>
            <Image
              src={ABOUT}
              style={
                isMobile
                  ? { display: "block", margin: "0 auto" }
                  : {
                      maxWidth: 1680,
                      width: "100%",
                      display: "block",
                      margin: "0 auto",
                    }
              }
            />
          </Container>
        </section>

        <Divider style={{ marginTop: 20, marginBottom: 0 }} />

        <section
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: isMobile ? 12 : 20,
          }}
        >
          <Segment>
            <Header as={"h2"}>
              카몬소식
              <Header.Subheader>카몬 소식을 전해드립니다.</Header.Subheader>
            </Header>
            <Card.Group itemsPerRow={isMobile ? 1 : 2}>
              {/*{NEWS_DATA.map((news, idx) => (*/}
              {/*  <Card fluid key={`news_${idx}`} link href={news.link} target={'_blank'}>*/}
              {/*    <div style={{*/}
              {/*      height: isMobile ? 200 : 400,*/}
              {/*      backgroundSize: 'cover',*/}
              {/*      backgroundPosition: 'center',*/}
              {/*      backgroundImage: `url(../images/news/${news.id}.png)`,*/}
              {/*    }} />*/}
              {/*    <Card.Content>*/}
              {/*      <Card.Header>{news.title}</Card.Header>*/}
              {/*      <Card.Meta>{news.lastModified} | 출처 : {news.pressCompany}</Card.Meta>*/}
              {/*      <Card.Description>*/}
              {/*        {news.outline}*/}
              {/*      </Card.Description>*/}
              {/*    </Card.Content>*/}
              {/*  </Card>*/}
              {/*))}*/}
            </Card.Group>
          </Segment>
        </section>
      </div>
    </div>
  );
}
